import { Layout } from "../../Components";

function Policies() {
  return (
    <Layout>
      <h2>Policies</h2>
    </Layout>
  );
}

export default Policies;
